const $ = require('jquery');

// форма
$('.js-show-form').click(function(){
	$('.layout-form').addClass('active')
	$('.layout-form-overlay').addClass('active')
	$('body').addClass('js-hidden')
});

$('.js-hide-form').click(function(){
	$('.layout-form').removeClass('active')
	$('.layout-form-overlay').removeClass('active')
	$('body').removeClass('js-hidden')
});